import React, { useEffect, useState } from "react";
import styles from "./CustomOrder.module.scss";
import { deleteIcon } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const CustomOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/custom-order/`
      );
      setOrders(response.data.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      // Set loading to false whether there's a success or an error
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleDelete = (orderId) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      axios
        .delete(`${BASE_URL}/custom-order/delete/${orderId}`)
        .then((response) => {
          toast.success("Order deleted successfully!");
          setOrders(orders.filter((order) => order.id !== orderId));
        })
        .catch((error) => {
          console.error("Error deleting order:", error);
          // Show error message
          toast.error("Error deleting order");
        });
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.custom_container}>
            <div className={styles.custom_container_top}>
              <h1>Custom Order Details</h1>
            </div>{" "}
            <div className={styles.custom_container_bottom}>
              <div className={styles.custom_container_bottom_left}>
                <table>
                  <thead>
                    <tr>
                      <th>Rug Size</th>
                      <th>Color</th>
                      <th>Material</th>
                      <th>Design</th>
                      <th>Personalization</th>
                      <th>Budget Range</th>
                      <th>Category</th>
                      <th>Contact</th>
                      <th>Additional Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((data) => {
                      return (
                        <tr key={data._id}>
                          <td>{data.rugSize}</td>
                          <td>{data.colorPreference}</td>
                          <td>{data.materialSelection}</td>
                          <td>{data.patternDesignRequest}</td>
                          <td>{data.personalization}</td>
                          <td>{data.budgetRange}</td>
                          <td>{data.category}</td>
                          <td>{data.contactInformation}</td>
                          <td>{data.additionalNotes}</td>
                          <td>
                            <img
                              src={deleteIcon}
                              alt="Delete"
                              onClick={() => handleDelete(data._id)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomOrder;
