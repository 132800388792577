import React, { useState } from "react";
import Styles from "./Signin.module.scss";
import FormInput from "../../FormInput/FormInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { google, logo } from "../../assets";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";

const Signin = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is Required"),
    password: Yup.string().required("Password is required"), 
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const apiUrl = `${BASE_URL}/admin/login`;
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password
      })
    })
    .then(async response => {
      const data = await response.json(); 
  
      if (response.ok) {
    
        navigate("/dashboard");
        toast.success("Login successfully")
      } else {
        setLoginError(data.message || 'Login failed. Please try again.');
        toast.error("Login failed. Please try again.")
      }
    })
    .catch(error => {
      setLoginError('An error occurred. Please try again later.');
      
    })
    .finally(() => {
      setSubmitting(false); 
    });
  };
  
  return (
    <>
      <div className={Styles.signin_container}>
        <div className={Styles.signin_container_content2}>
          <div className={Styles.signin_container_content2_box1}>
            <img src={logo} alt="Display" />
            <h1>Rug Bazaar Login</h1>
            <div className={Styles.signin_container_content2_box1_content}>
            <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validate}
      onSubmit={handleSubmit} 
    >
                {(formik) => (
                  <div>
                    <Form>
                      <FormInput
                        label="Email"
                        name="email"
                        type="email"
                        place="example@email.com"
                      />
                      <FormInput
                        label="Password"
                        name="password"
                        type="password"
                        place="**************"
                      />
                      
                      <button
            type="submit" 
            className={Styles.signin_container_content2_box1_content_btn}
          >
            Login
          </button>
          {/* {loginError && <p>{loginError}</p>}  */}
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Signin;
