import React, { useState, useEffect } from "react";
import styles from "./Notification.module.scss";
import FormInput from "../../FormInput/FormInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { CategoryItem, deleteIcon, search } from "../../assets";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Notification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  const validate = Yup.object({
    notificationImage: Yup.string().required("Image is required"),
    name: Yup.string().required("Name is required"),
  });

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${BASE_URL}/notification`);
      const data = await response.json();
      if (data.success) {
        setNotificationList(data.data);
      } else {
       console.log(data.message || "Error fetching notifications");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("title", values.name);
    formData.append("description", values.description);
    formData.append("image", values.notificationImage);

    try {
      const response = await fetch(
        `${BASE_URL}/notification/create`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success(data.message || "Notification created successfully");
        setNotificationList([ data.data,...notificationList]);
        resetForm({});
      } else {
        toast.error(data.message || "Failed to create notification");
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    }
  };

  const handleDelete = async (notificationId) => {
    try {
      const response = await fetch(
        `${BASE_URL}/notification/${notificationId}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message || "Notification deleted successfully");
        setNotificationList(
          notificationList.filter(
            (notification) => notification._id !== notificationId
          )
        );
      } else {
        throw new Error(data.message || "Error deleting notification");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.notification_container}>
            <div className={styles.notification_container_top}>
              <h1>Add Notifications</h1>
            </div>
            <div className={styles.notification_container_bottom}>
              <div className={styles.notification_container_bottom_addNew}>
                <Formik
                  initialValues={{
                    notificationImage: "",
                    name: "",
                    description: "",
                  }}
                  validationSchema={validate}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <label htmlFor="fileInput" className={styles.label}>
                          Notification Image
                        </label>
                        <input
                          type="file"
                          name="notificationImage"
                          id="fileInput"
                          accept="image/*"
                          className={styles.inputChoose}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            formik.setFieldValue("notificationImage", file);
                          }}
                        />
                        <div
                          className={
                            styles.notification_container_bottom_addNew_fields
                          }
                        >
                          <div
                            className={
                              styles.notification_container_bottom_addNew_fields_left
                            }
                          >
                            <label className={styles.label}>
                              notification Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />

                            {formik.touched.name && formik.errors.name && (
                              <div className={styles.error}>
                                {formik.errors.name}
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              styles.notification_container_bottom_addNew_fields_left
                            }
                          >
                            <label className={styles.label}>
                              notification Discription
                            </label>
                            <input
                              type="text"
                              name="description"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                            />
                            {/* Error message for notification description */}
                            {formik.touched.description &&
                              formik.errors.description && (
                                <div className={styles.error}>
                                  {formik.errors.description}
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className={styles.notification_container_bottom_btn}
                        >
                          <button type="Submit">Add Notification</button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className={styles.notification_container_bottom_listing}>
                <h1>Notification Listing</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Notification Image</th>
                      <th>Notification Name</th>
                      <th>Notification Discription</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationList.map((notification, index) => (
                      <tr key={notification._id || index}>
                        <td>
                          <img
                            src={`${BASE_URL}/${notification.image}`}
                            alt="Display"
                            crossorigin="anonymous"
                          />
                        </td>
                        <td>{notification.title}</td>
                        <td>{notification.description}</td>
                        <td>
                          <img
                            src={deleteIcon}
                            alt="Delete Icon"
                            onClick={() => handleDelete(notification._id)}
                            style={{ cursor: "pointer" }}
                       
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Notification;
