export { default as Signin } from "./auth/Signin";
export { default as Dashboard } from "./dashboard/Dashboard";
export { default as Category } from "./category/Category";
export { default as Manageempolyee } from "./manageemployee/Manageempolyee";
export { default as Settings } from "./settings/Settings";
export { default as Product } from "./product/Product";
export { default as Orders } from "./orders/Orders";
export { default as Notification } from "./notification/Notification";
export { default as CustomOrder } from "./customOrder/CustomOrder";
export { default as Gallery } from "./gallery/Gallery";
// export { default as Subscription } from "./";
