import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import PropagateLoader from "react-spinners/PropagateLoader";

import { graphMenu, search, tableUserImage, deleteIcon } from "../../assets";
import Grid from "@mui/material/Grid";
import { Chart } from "chart.js";
import {
  Chart as ChartJS,
  Title,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ArcElement,
  Filler,
} from "chart.js";
import { Pie, Line } from "react-chartjs-2";
import axios from "axios";
import { BASE_URL } from "../../config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

Chart.register(ArcElement);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const data = {
  labels: ["Basic", "Professional", "Small business"],
  datasets: [
    {
      data: [3, 6, 7],
      backgroundColor: ["#8A8A8A", "#BF9969", "#73242A"],
    },
  ],
};

const Dashboard = () => {
  const [dashboardCounts, setDashboardCounts] = useState({
    userCount: 0,
    orderCount: 0,
    productCount: 0,
    totalRevenue: 0,
  });
  const [lineGraphData, setLineGraphData] = useState([]);
  const [lineGraphLabels, setLineGraphLabels] = useState([]);
  const [lineUserGraphData, setlineUserGraphData] = useState([]);

  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/order`);
        setOrders(response.data.data.slice(0, 10));
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchLineGraphData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/gallery/dashboard-count`
        );
        if (response.data.success) {
          const {
            userCount,
            orderCount,
            productCount,
            totalRevenue,
            lineGraphLabels,
            lineGraphData,
            lineUserGraphData,
          } = response.data.data;
          setDashboardCounts({
            userCount,
            orderCount,
            productCount,
            totalRevenue,
          });
          setLineGraphData(lineGraphData);
          setLineGraphLabels(lineGraphLabels);
          setlineUserGraphData(lineUserGraphData);
        } else {
          throw new Error(
            response.data.message || "Failed to fetch line graph data"
          );
        }
      } catch (error) {
        console.error("Error fetching line graph data:", error);
      } finally {
        // Set loading to false whether there's a success or an error
        setLoading(false);
      }
    };

    fetchLineGraphData();

    fetchOrders();
  }, []);

  const lineChartData = {
    labels: lineGraphLabels,
    datasets: [
      {
        data: lineGraphData,
        pointRadius: 5,
        pointBackgroundColor: "#73242A",
        borderColor: "#73242A",
        borderWidth: 2,
        backgroundColor: "rgba(115, 36, 42, 0.5)",
      },
    ],
  };

  const lineChartuuser = {
    labels: lineGraphLabels,
    datasets: [
      {
        data: lineUserGraphData,
        pointRadius: 5,
        pointBackgroundColor: "#73242A",
        borderColor: "#73242A",
        borderWidth: 2,
        backgroundColor: "rgba(115, 36, 42, 0.5)",
        fill: false,
      },
    ],
  };

  const dataList = [
    {
      dataListTitle: "Total Revenue",
      dataListNumber: `£ ${dashboardCounts.totalRevenue.toLocaleString()}`,
    },
    {
      dataListTitle: "Total Orders",
      dataListNumber: dashboardCounts.orderCount.toLocaleString(),
    },
    {
      dataListTitle: "Total Products",
      dataListNumber: dashboardCounts.productCount.toLocaleString(),
    },
    {
      dataListTitle: "Total Users",
      dataListNumber: dashboardCounts.userCount.toLocaleString(),
    },
  ];

  const toggleModal = async (order) => {
    setSelectedOrder(order);
    setModalVisible(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/order/detail/${order._id}`
      );

      setOrderDetails(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setOrderDetails(null);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await axios.delete(`${BASE_URL}/order/${orderId}`);
      const updatedOrders = orders.filter((order) => order._id !== orderId);
      setOrders(updatedOrders);
    } catch (error) {
      console.error("Error deleting the order:", error);
    }
  };

  const orderupdateStatus = async (orderId, newStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/order/update/${orderId}`,
        {
          status: newStatus,
        }
      );

      if (response.data.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === orderId) {
            return { ...order, status: newStatus }; // Update the status here
          }
          return order;
        });
        setOrders(updatedOrders);
      } else {
        console.error("Failed to update the order status");
      }
    } catch (error) {
      console.error("Error updating the order status:", error);
    }
  };

  const deliveryStatus = async (orderId, newDeliveryStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/order/update-order-accept-status/${orderId}`,
        {
          orderDelivery: newDeliveryStatus,
        }
      );

      if (response.data.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === orderId) {
            return { ...order, orderDelivery: newDeliveryStatus }; // Update the delivery status here
          }
          return order;
        });
        setOrders(updatedOrders);
      } else {
        console.error("Failed to update the delivery status");
      }
    } catch (error) {
      console.error("Error updating the delivery status:", error);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.loader_wrapper}>
          <PropagateLoader
            color={color}
            loading={loading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className={styles.dashboard_container}>
          <div className={styles.dashboard_container_content1}>
            <h1>Dashboard</h1>
            {/* <select>
                <option>Mar 2023</option>
                <option>Mar 2024</option>
                <option>Mar 2025</option>
              </select> */}
          </div>
          <div className={styles.wrappe_content}>
            <div className={styles.dashboard_container_content2}>
              <Grid container spacing={3}>
                {dataList.map((data, ind) => {
                  return (
                    <Grid item key={ind} xs={6} sm={6} md={3} lg={3}>
                      <div className={styles.analysis_card}>
                        <div className={styles.analysis_card_content}>
                          <div className={styles.analysis_card_content_left}>
                            <span>{data.dataListTitle} </span>
                            <h1>{data.dataListNumber}</h1>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className={styles.dashboard_container_content3}>
              <div className={styles.dashboard_container_content3_chartbox}>
                <div
                  className={styles.dashboard_container_content3_chartbox_left}
                >
                  <div
                    className={
                      styles.dashboard_container_content3_chartbox_left_content
                    }
                  >
                    <div
                      className={
                        styles.dashboard_container_content3_chartbox_left_content_menu
                      }
                    >
                      <h1>Monthly Renenue</h1>
                      <img src={graphMenu} alt="Icon" />
                    </div>
                    <Line data={lineChartData} options={options} />
                  </div>
                </div>
                <div
                  className={styles.dashboard_container_content3_chartbox_right}
                >
                  <div
                    className={
                      styles.dashboard_container_content3_chartbox_right_content
                    }
                  >
                    <div
                      className={
                        styles.dashboard_container_content3_chartbox_right_content_menu
                      }
                    >
                      <h1>Registered User Chart</h1>
                      <img src={graphMenu} alt="Icon" />
                    </div>
                    <div className="line-user-chart">
                      <Line data={lineChartuuser} options={options} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dashboard_container_content4}>
              <div className={styles.dashboard_container_content4_table}>
                <div className={styles.dashboard_container_content4_table_top}>
                  <h1>Latest Orders</h1>
                </div>
              </div>
              <div className={styles.dashboard_container_content4_table_content}>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Order ID</th>
                      <th>Payment Method</th>
                      <th>Total Price</th>
                      <th>Created At</th>
                      <th>Order Status</th>
                      <th>Delivery Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td onClick={() => toggleModal(order)}>{order.name}</td>
                        <td>{order.email}</td>
                        <td>{order.phoneNumber}</td>
                        <td>{order._id}</td>
                        <td>{order.paymentMethod}</td>
                        <td>${order.totalAmount}</td>
                        <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                        <td>
                          <select
                            value={order.status}
                            onChange={(e) =>
                              orderupdateStatus(order._id, e.target.value)
                            }
                          >
                            <option value="pending">Pending</option>
                            <option value="accept">accept</option>
                          </select>
                        </td>
                        <td>
                          <div>
                            <td>
                              <select
                                value={order.orderDelivery}
                                onChange={(e) =>
                                  deliveryStatus(order._id, e.target.value)
                                }
                              >
                                <option value="pending">Pending</option>
                                <option value="delivered">Delivered</option>
                              </select>
                            </td>
                          </div>
                        </td>
                        <td>
                          <img
                            src={deleteIcon}
                            alt="Delete Icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteOrder(order._id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {modalVisible && selectedOrder && orderDetails && (
              <div
                className={styles.orderInfo_container}
                onClick={() => setModalVisible(false)}
              >
                <div
                  className={styles.orderInfo_container_content}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h1>Product Order Information</h1>
                  <div
                    className={styles.orderInfo_container_content_innerContent}
                  >
                    <div
                      className={
                        styles.orderInfo_container_content_innerContent_profile
                      }
                    >
                      <img src={tableUserImage} alt="Profile Icon" />
                      <h2>{selectedOrder.name}</h2>
                    </div>
                    <div
                      className={
                        styles.orderInfo_container_content_innerContent_otherFields
                      }
                    >
                      {orderDetails.orderAddress && (
                        <>
                          <h3>Address</h3>
                          <p>
                            Full Address: {orderDetails.orderAddress.line_1},{" "}
                            {orderDetails.orderAddress.line_2}
                          </p>
                          <p>
                            City/Country: {orderDetails.orderAddress.town},{" "}
                            {orderDetails.orderAddress.country}
                          </p>
                          <p>PostCode: {orderDetails.orderAddress.postcode}</p>
                        </>
                      )}

                      {orderDetails.orderProducts && (
                        <>
                          <h3>Products</h3>
                          {orderDetails.orderProducts.map((product, index) => (
                            <div key={index}>
                              <p>Title: {product.productId.productTitle}</p>
                              <p>Quantity: {product.quantity}</p>
                              <p>Price: ${product.amount}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
