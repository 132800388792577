import React from "react";
import { ChangePasword } from "./components";
import Layout from "./layout/Layout";
import {
  Category,
  CustomOrder,
  Dashboard,
  Gallery,
  Manageempolyee,
  Notification,
  Orders,
  Product,
  Settings,
  Signin,
} from "./module";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="" element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/category" element={<Category />} />
            <Route path="/product" element={<Product />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/manageEmployee" element={<Manageempolyee />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/customorder" element={<CustomOrder />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/changepasword" element={<ChangePasword />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
