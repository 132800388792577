import "./App.css";
import Router from "./Router";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <>
          <ToastContainer />
      <div className="wrapp_container">
        <Router/>
      </div>
    </>
  );
}

export default App;
