import React, { useEffect, useState } from "react";
import styles from "./Orders.module.scss";
import { deleteIcon, tableUserImage } from "../../assets";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/order`);
        setOrders(response.data.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }finally {
        // Set loading to false whether there's a success or an error
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const toggleModal = async (order) => {
    setSelectedOrder(order);
    setModalVisible(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/order/detail/${order._id}`
      );

      setOrderDetails(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setOrderDetails(null);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await axios.delete(`${BASE_URL}/order/${orderId}`);
      const updatedOrders = orders.filter((order) => order._id !== orderId);
      setOrders(updatedOrders);
    } catch (error) {
      console.error("Error deleting the order:", error);
    }
  };

  const orderupdateStatus = async (orderId, newStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/order/update/${orderId}`,
        {
          status: newStatus,
        }
      );

      if (response.data.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === orderId) {
            return { ...order, status: newStatus }; // Update the status here
          }
          return order;
        });
        setOrders(updatedOrders);
      } else {
        console.error("Failed to update the order status");
      }
    } catch (error) {
      console.error("Error updating the order status:", error);
    }
  };

  const deliveryStatus = async (orderId, newDeliveryStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/order/update-order-accept-status/${orderId}`,
        {
          orderDelivery: newDeliveryStatus,
        }
      );

      if (response.data.success) {
        const updatedOrders = orders.map((order) => {
          if (order._id === orderId) {
            return { ...order, orderDelivery: newDeliveryStatus }; // Update the delivery status here
          }
          return order;
        });
        setOrders(updatedOrders);
      } else {
        console.error("Failed to update the delivery status");
      }
    } catch (error) {
      console.error("Error updating the delivery status:", error);
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.order_container}>
            <div className={styles.order_container_top}>
              <h1>Orders List</h1>
            </div>
            <div className={styles.order_container_bottom}>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Order ID</th>
                    <th>Payment Method</th>
                    <th>Total Price</th>
                    <th>Created At</th>
                    <th>Order Status</th>
                    <th>Delivery Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order._id}>
                      <td onClick={() => toggleModal(order)}>{order.name}</td>
                      <td>{order.email}</td>
                      <td>{order.phoneNumber}</td>
                      <td>{order._id}</td>
                      <td>{order.paymentMethod}</td>
                      <td>${order.totalAmount}</td>
                      <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td>
                        <select
                          value={order.status}
                          onChange={(e) =>
                            orderupdateStatus(order._id, e.target.value)
                          }
                        >
                          <option value="pending">Pending</option>
                          <option value="accept">accept</option>
                        </select>
                      </td>
                      <td>
                        <div>
                          <td>
                            <select
                              value={order.orderDelivery}
                              onChange={(e) =>
                                deliveryStatus(order._id, e.target.value)
                              }
                            >
                              <option value="pending">Pending</option>
                              <option value="delivered">Delivered</option>
                            </select>
                          </td>
                        </div>
                      </td>
                      <td>
                        <img
                          src={deleteIcon}
                          alt="Delete Icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteOrder(order._id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {modalVisible && selectedOrder && orderDetails && (
            <div
              className={styles.orderInfo_container}
              onClick={() => setModalVisible(false)}
            >
              <div
                className={styles.orderInfo_container_content}
                onClick={(e) => e.stopPropagation()}
              >
                <h1>Product Order Information</h1>
                <div
                  className={styles.orderInfo_container_content_innerContent}
                >
                  <div
                    className={
                      styles.orderInfo_container_content_innerContent_profile
                    }
                  >
                    <img src={tableUserImage} alt="Profile Icon" />
                    <h2>{selectedOrder.name}</h2>
                  </div>
                  <div
                    className={
                      styles.orderInfo_container_content_innerContent_otherFields
                    }
                  >
                    {orderDetails.orderAddress && (
                      <>
                        <h3>Address</h3>
                        <p>
                          Full Address: {orderDetails.orderAddress.line_1},{" "}
                          {orderDetails.orderAddress.line_2}
                        </p>
                        <p>
                          City/Country: {orderDetails.orderAddress.town},{" "}
                          {orderDetails.orderAddress.country}
                        </p>
                        <p>PostCode: {orderDetails.orderAddress.postcode}</p>
                      </>
                    )}

                    {orderDetails.orderProducts && (
                      <>
                        <h3>Products</h3>
                        {orderDetails.orderProducts.map((product, index) => (
                          <div key={index}>
                            <p>Title: {product.productId.productTitle}</p>
                            <p>Quantity: {product.quantity}</p>
                            <p>Price: ${product.amount}</p>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Orders;
