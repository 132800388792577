import React, { useState, useEffect } from "react";
import styles from "./Category.module.scss";
import FormInput from "../../FormInput/FormInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { CategoryItem, deleteIcon, search } from "../../assets";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Category = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  const validate = Yup.object({
    categoryImage: Yup.string().required("Image is required"),
    name: Yup.string().required("Name is required"),
  });

  const notify = (isSuccess) => {
    if (isSuccess) {
      toast.success("Category added successfully!", {
        position: "top-center",
      });
    } else {
      toast.error("Failed to add", {
        position: "top-center",
      });
    }
  };



  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BASE_URL}/category`);

      if (response.ok) {
        const responseData = await response.json();

        const categories =
          responseData.data && Array.isArray(responseData.data)
            ? responseData.data
            : [];

        console.log("Fetched categories:", categories);

        setCategoryList(categories);
      } else {
        console.error("Error fetching categories. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []); 


  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("categoryImage", values.categoryImage);
      formData.append("name", values.name);

      const response = await fetch(
       `${BASE_URL}/category/create`,
        {
          method: "POST",
          body: formData,
        }
      );


      if (response.ok) {
        const responseData = await response.json();
        const newProduct = responseData.data;
        setCategoryList(prevProductList => [newProduct, ...prevProductList]);
      
  
        console.log("Category added successfully!");
        notify(true);
        fetchCategories();
      } 
       else {
        console.error("Error adding category. Please try again.");
        notify(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      notify(false);
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      const response = await fetch(`${BASE_URL}/category/${categoryId}`, {
        method: "DELETE",
      });
  
      if (response.status === 200) {
        console.log("Category deleted successfully!");
  
        const updatedCategoryList = categoryList.filter(category => category._id !== categoryId);
        setCategoryList(updatedCategoryList);
  
      } else {
        console.error("Error deleting category. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.category_container}>
            <div className={styles.category_container_top}>
              <h1>Add Categories</h1>
            </div>
            <div className={styles.category_container_bottom}>
              <div className={styles.category_container_bottom_addNew}>
                <Formik
                  initialValues={{
                    categoryImage: "",
                    name: "",
                  }}
                  validationSchema={validate}
                  onSubmit={handleSubmit} // Add the onSubmit handler
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <label htmlFor="fileInput" className={styles.label}>
                          Category Image
                        </label>
                        <input
                          type="file"
                          name="categoryImage" // Corrected the name attribute
                          id="fileInput"
                          accept="image/*"
                          className={styles.inputChoose}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            formik.setFieldValue("categoryImage", file);
                          }}
                        />
                        <div
                          className={
                            styles.category_container_bottom_addNew_fields
                          }
                        >
                          <div
                            className={
                              styles.category_container_bottom_addNew_fields_left
                            }
                          >
                            <label className={styles.label}>
                              Category Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.categoryName}
                            />
                            {formik.touched.categoryName &&
                              formik.errors.categoryName && (
                                <div className={styles.error}>
                                  {formik.errors.categoryName}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={styles.category_container_bottom_btn}>
                          <button type="Submit">Add Category</button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className={styles.category_container_bottom_listing}>
                <h1>Category Listing</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Category Image</th>
                      <th>Category Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList.map((category, index) => (
                      <tr key={index}>
                        <td>
                          {/* Display your category image here */}
                          <img
                            src={`${BASE_URL}/${category.categoryImage}`}
                            alt="Display"
                            crossorigin="anonymous"
                          />
                        </td>
                        <td>{category.name}</td>
                        <td>
                          {/* its delete Icon here i wan to intgrate delete api   */}
                          <img
                            src={deleteIcon}
                            alt="Icon"
                            onClick={() => handleDelete(category._id)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Category;
