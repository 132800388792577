import React, { useState } from "react";
import styles from "./Sidebar.module.scss";
import {
  BellIcon,
  GalleryIcon,
  avatar,
  dashboard,
  dashboardWithoutBackground,
  logo,
  logout,
  manageEmployee,
  setting,
  subscription,
  supportRequest,
} from "../../assets";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LuBellRing } from "react-icons/bi";
import { BiSolidCustomize } from "react-icons/bi";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getColor = (current) => {
    if (location.pathname === current) {
      return "#BF9969";
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    navigate("/");
  };
  const data = [
    {
      path: "/dashboard",
      icon: dashboardWithoutBackground,
      title: "Dashboard",
    },
    {
      path: "/category",
      icon: supportRequest,
      title: "Category",
    },
    {
      path: "/product",
      icon: subscription,
      title: "Product",
    },

    {
      path: "/manageEmployee",
      icon: manageEmployee,
      title: "Users",
    },
    {
      path: "/orders",
      icon: subscription,
      title: "Orders",
    },
    {
      path: "/customorder",
      icon: dashboardWithoutBackground,
      title: "Custom Order",
    },
    {
      path: "/notification",
      icon: BellIcon,
      title: "Notifications",
    },
    {
      path: "/gallery",
      icon: GalleryIcon,
      title: "Gallery",
    },
  ];

  return (
    <>
      <div className={styles.sidebar}>
        <div className={styles.sidebar_top}>
          <img src={logo} alt="Logo" onClick={() => navigate("/")} />
        </div>
        <div className={styles.sidebar_middle}>
          {" "}
          {data.map((item, ind) => {
            return (
              <button
                key={ind}
                onClick={() => navigate(item.path)}
                className={styles.btn}
              >
                {/* <img src={item.icon} alt="Icon" /> */}
                {typeof item.icon === "string" ? ( // Check if the icon is a string (image URL)
                  <img src={item.icon} alt="Icon" />
                ) : (
                  // If it's not a string, assume it's a React component (like an icon)
                  item.icon
                )}
                <span
                  className={styles.text}
                  style={{ color: getColor(item.path) }}
                >
                  {item.title}
                </span>
              </button>
            );
          })}
        </div>
        <div className={styles.logoutContainer}>
          <button onClick={handleLogout}>Log Out</button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
