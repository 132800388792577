import React, { useEffect, useState } from "react";
import styles from "./Product.module.scss";
import * as Yup from "yup";
import { CategoryItem, deleteIcon } from "../../assets";
import { Formik, Form, FieldArray, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Product = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  const notify = (isSuccess) => {
    if (isSuccess) {
      toast.success("Product added successfully!", {
        position: "top-center",
      });
    } else {
      toast.error("Failed to add", {
        position: "top-center",
      });
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/product`);

      if (response.ok) {
        const responseData = await response.json();
        const products =
          responseData.data && Array.isArray(responseData.data)
            ? responseData.data
            : [];
            
        setProductList(products);
      } else {
        console.error("Error fetching products. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch categories when the component mounts
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BASE_URL}/category`);
      if (response.ok) {
        const responseData = await response.json();
        const categories =
          responseData.data && Array.isArray(responseData.data)
            ? responseData.data
            : [];

        console.log("Fetched categories:", categories);

        setCategoryList(categories);
      } else {
        console.error("Error fetching categories. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred while fetching categories:", error);
    } finally {
      // Set loading to false whether there's a success or an error
      setLoading(false);
    }
  };

  const fetchProductImages = async () => {
    const response = await fetch(`${BASE_URL}/productImage`);
    const data = await response.json();
    setProductImages(data.data || []);
  };
 

  const getImageUrlForProduct = (productId) => {
    const image = productImages.find((img) => img.productId === productId);
    // console.log('hello' image);
    return image
      ? `${BASE_URL}/${image.productImage}`
      : undefined;
  };
  const validate = Yup.object({
    // productImages: Yup.array()
    // .min(1, "Please upload at least one image")
    // .required("Images are required"),
    productImage: Yup.string().required("Image is required"),
    productTitle: Yup.string().required("Title is required"),
    price: Yup.string().required("Price is required"),
    discountedPrice: Yup.string(),
    measurement: Yup.string(),
    offerStart: Yup.string(),
    offerEnd: Yup.string(),
    description: Yup.string(),
    additionalInfo: Yup.string(),
    sku: Yup.string().required("SKU is required"),
    suitableProductFor: Yup.string(),
    categoryId: Yup.string().required("Category is required"),
    is_featured: Yup.string().required("Feature status is required"),
  });

  const handleSubmit = async (values, formikBag) => {
    try {
      console.log("Submitting form with values:", values);
      const isFeaturedBoolean = values.is_featured === "1";
      const formData = new FormData();
      for (let i = 0; i < values.productImage.length; i++) {
        formData.append("productImage", values.productImage[i]);
      }
      formData.append("productTitle", values.productTitle);
      formData.append("price", values.price);
      formData.append("discountedPrice", values.discountedPrice);
      formData.append("measurement", values.measurement);
      formData.append("offerStart", values.offerStart);
      formData.append("offerEnd", values.offerEnd);
      formData.append("description", values.description);
      formData.append("additionalInfo", values.additionalInfo);
      formData.append("sku", values.sku);
      formData.append("suitableProductFor", values.suitableProductFor);
      formData.append("categoryId", values.categoryId);
      formData.append("is_featured", isFeaturedBoolean);
  
      const productResponse = await fetch(`${BASE_URL}/product/create`, {
      method: "POST",
      body: formData,
    });

    if (productResponse.ok) {
      const responseData = await productResponse.json();
      const newProduct = responseData.data;
      setProductList(prevProductList => [newProduct, ...prevProductList]);
      fetchProductImages();

      toast.success("Product added successfully!");
    } else {
      console.error("Error posting product. Please try again.");
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

const handleDelete = async (productId) => {
  try {
    const response = await fetch(`${BASE_URL}/product/${productId}`, {
      method: "DELETE",
    });

    if (response.ok) {
      console.log("Product deleted successfully!");
      const updatedProductList = productList.filter(product => product._id !== productId);
      setProductList(updatedProductList);

      toast.success("Product deleted successfully!");
    } else {
      console.error("Error deleting product. Please try again.");
      toast.error("Failed to delete product");
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error("An error occurred while deleting the product");
  }
};

  useEffect(() => {
    fetchCategories();
    fetchProducts();
    fetchProductImages();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.product_container}>
            <div className={styles.product_container_top}>
              <h1>Add Products</h1>
            </div>
            <div className={styles.product_container_bottom}>
              <div className={styles.product_container_bottom_addNew}>
                <Formik
                  initialValues={{
                    productImage: [],
                    productTitle: "",
                    price: "",
                    discountedPrice: "",
                    additionalInfo:"",
                    measurement: "",
                    offerStart: "",
                    offerEnd: "",
                    description: "",
                    sku: "",
                    suitableProductFor: "",
                    categoryId: "",
                    is_featured: "0",
                  }}
                  validationSchema={validate}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <div>
                      <Form encType="multipart/form-data">
                        <label htmlFor="fileInput" className={styles.label}>
                          Add Media
                        </label>

                        <input
                          type="file"
                          name="productImage"
                          id="fileInput"
                          accept="image/*"
                          multiple
                          className={styles.inputChoose}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "productImage",
                              event.target.files
                            );
                          }}
                        />

                        {/* <input
                  type="file"
                  name="productImage"
                  id="fileInput"
                  accept="image/*"
                  className={styles.inputChoose}
                  onChange={(event) => {
                    formik.setFieldValue("productImage", event.target.files);
                  }}
                  multiple
                /> */}

                        <h1 className={styles.label}>Product Details</h1>
                        <div
                          className={
                            styles.product_container_bottom_addNew_fields
                          }
                        >
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields_left
                            }
                          >
                            <label className={styles.label}>
                              Product Title
                            </label>
                            <input
                              type="text"
                              name="productTitle"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.productTitle}
                            />
                            {formik.touched.productTitle &&
                              formik.errors.productTitle && (
                                <div className={styles.error}>
                                  {formik.errors.productTitle}
                                </div>
                              )}
                          </div>
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields_middle
                            }
                          >
                            <label className={styles.label}>Price</label>
                            <input
                              type="text"
                              name="price"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.price}
                            />
                            {formik.touched.price && formik.errors.price && (
                              <div className={styles.error}>
                                {formik.errors.price}
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields_right
                            }
                          >
                            <label className={styles.label}>
                              Discounted Price
                            </label>
                            <input
                              type="text"
                              name="discountedPrice"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.discountedPrice}
                            />
                            {formik.touched.discountedPrice &&
                              formik.errors.discountedPrice && (
                                <div className={styles.error}>
                                  {formik.errors.discountedPrice}
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className={
                            styles.product_container_bottom_addNew_fields2
                          }
                        >
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields2_left
                            }
                          >
                            <label className={styles.label}>
                              Offer Start Date
                            </label>
                            <input
                              type="date"
                              name="offerStart"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.offerStart}
                            />
                            {formik.touched.offerStart &&
                              formik.errors.offerStart && (
                                <div className={styles.error}>
                                  {formik.errors.offerStart}
                                </div>
                              )}
                          </div>
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields2_middle
                            }
                          >
                            <label className={styles.label}>
                              Offer End Date
                            </label>
                            <input
                              type="date"
                              name="offerEnd"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.offerEnd}
                            />
                            {formik.touched.offerEnd &&
                              formik.errors.offerEnd && (
                                <div className={styles.error}>
                                  {formik.errors.offerEnd}
                                </div>
                              )}
                          </div>
                          <div
                            className={
                              styles.product_container_bottom_addNew_fields2_right
                            }
                          >
                            <label className={styles.label}>Measurement</label>
                            <input
                              type="text"
                              name="measurement"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.measurement}
                            />
                            {formik.touched.measurement &&
                              formik.errors.measurement && (
                                <div className={styles.error}>
                                  {formik.errors.measurement}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={styles.product_description}>
                          <label className={styles.label}>Description</label>
                          <input
                            type="text"
                            name="description"
                            className={styles.input}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <div className={styles.error}>
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                        <div className={styles.product_additional}>
                          <label className={styles.label}>
                            Additional Information
                          </label>
                          <input
                            type="text"
                            name="additionalInfo"
                            className={styles.input}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.additionalInfo}
                          />
                          {formik.touched.additionalInfo &&
                            formik.errors.additionalInfo && (
                              <div className={styles.error}>
                                {formik.errors.additionalInfo}
                              </div>
                            )}
                        </div>
                        <div className={styles.product_category}>
                          <label className={styles.label}>
                            Product Category
                          </label>
                          <select
                            name="categoryId"
                            className={styles.input}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.categoryId}
                          >
                            <option value="" label="Select a category" />
                            {categoryList.map((category) => (
                              <option value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.categoryId &&
                            formik.errors.categoryId && (
                              <div className={styles.error}>
                                {formik.errors.categoryId}
                              </div>
                            )}
                        </div>
                        <div className={styles.product_featured}>
                          <label className={styles.label}>
                            Product featured
                          </label>
                          <select
                            name="is_featured"
                            className={styles.input}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.is_featured}
                          >
                            <option value="0" label="Unfeatured" />
                            <option value="1" label="Featured" />
                          </select>
                          {formik.touched.is_feature &&
                            formik.errors.is_feature && (
                              <div className={styles.error}>
                                {formik.errors.is_feature}
                              </div>
                            )}
                        </div>
                        <div className={styles.sku_container}>
                          <div className={styles.sku_container_left}>
                            <label className={styles.label}>SKU</label>
                            <input
                              type="text"
                              name="sku"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.sku}
                            />
                            {formik.touched.sku && formik.errors.sku && (
                              <div className={styles.error}>
                                {formik.errors.sku}
                              </div>
                            )}
                          </div>
                          <div className={styles.sku_container_right}>
                            <label className={styles.label}>
                              Product Suitable For
                            </label>
                            <input
                              type="text"
                              name="suitableProductFor"
                              className={styles.input}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.suitableProductFor}
                            />
                            {formik.touched.suitableProductFor &&
                              formik.errors.suitableProductFor && (
                                <div className={styles.error}>
                                  {formik.errors.suitableProductFor}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={styles.product_container_bottom_btn}>
                          <button type="submit">Add Product</button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              {/* Product Listing */}
              <div className={styles.product_container_bottom_listing}>
                <h1>Product Listing</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Product Image</th>
                      <th>Product Name</th>
                      <th>Product Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList.map((product, index) => (
                      <tr key={product._id || index}>
                        <td>
                          <img
                            src={getImageUrlForProduct(product._id)}
                            alt={product.productTitle}
                            crossorigin="anonymous"
                          />
                        </td>
                        <td>{product.productTitle}</td>
                        <td>{product.price}</td>
                        <td>
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            onClick={() => handleDelete(product._id)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Product;
