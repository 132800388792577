import React, { useEffect, useState } from "react";
import styles from "./Manageempolyee.module.scss";
import { deleteIcon, search } from "../../assets";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Manageempolyee = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  useEffect(() => {
    fetch(`${BASE_URL}/user`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.user)) {
          setUsers(data.user);
        } else {
          console.error("Expected an array of users, but got:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching users:", error))
      .finally(() => {
        // Set loading to false whether there's a success or an error
        setLoading(false);
      });
  }, []);
  const handleDelete = (userId) => {
    fetch(`${BASE_URL}/user/${userId}`, {
      method: "DELETE",
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user._id !== userId)
          );
        } else {
          console.error("Failed to delete the user with response:", response);
        }
      })
      .catch((error) => console.error("Error deleting user:", error));
  };

  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.manage_container}>
            <div className={styles.manage_container_top}>
              <h1>User List</h1>
            </div>
            <div className={styles.manage_container_bottom}>
              <div className={styles.manage_container_bottom_left}>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.mobileNumber}</td>
                        <td className="text-start">
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            onClick={() => handleDelete(user._id)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Manageempolyee;
