import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Gallery.module.scss";
import { deleteIcon } from "../../assets"; // Make sure this path is correct
import PropagateLoader from "react-spinners/PropagateLoader";
import { BASE_URL } from "../../config";

const Gallery = () => {
  const [galleryList, setGalleryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#BF9969");

  const gallerySchema = Yup.object().shape({
    galleryImage: Yup.mixed().required("An image is required"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append("image", values.galleryImage);

    axios
      .post(`${BASE_URL}/gallery/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("Gallery Image is uploaded Successfully!");
        setGalleryList([...galleryList, response.data.data]);
        resetForm();
      })
      .catch((error) => {
        toast.error("Upload failed");
        console.error("Error:", error);
      })
      .finally(() => {
        setSubmitting(false);
     //    setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/gallery`)
      .then((response) => {
        setGalleryList(response.data.data);
      })
      .catch((error) => {
        toast.error("Failed to fetch gallery images");
        console.error("Error fetching gallery images:", error);
      })
      .finally(()=>{
          setLoading(false)
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${BASE_URL}/gallery/delete/${id}`)
      .then(() => {
        const updatedGalleryList = galleryList.filter(
          (gallery) => gallery._id !== id
        );
        setGalleryList(updatedGalleryList);
        toast.success("Image deleted successfully");
      })
      .catch((error) => {
        toast.error("Failed to delete image");
        console.error("Error deleting image:", error);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div className={styles.loader_wrapper}>
            <PropagateLoader
              color={color}
              loading={loading}
              // css={override}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.gallery_container}>
            <div className={styles.gallery_container_top}>
              <h1>Add Gallery Images</h1>
            </div>
            <div className={styles.gallery_container_bottom}>
              <div className={styles.gallery_container_bottom_addNew}>
                <Formik
                  initialValues={{ galleryImage: undefined }}
                  validationSchema={gallerySchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, isSubmitting }) => (
                    <Form>
                      <label htmlFor="fileInput" className={styles.label}>
                        Gallery Image
                      </label>
                      <input
                        type="file"
                        name="galleryImage"
                        id="fileInput"
                        accept="image/*"
                        className={styles.inputChoose}
                        onChange={(event) => {
                          setFieldValue(
                            "galleryImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <div className={styles.gallery_container_bottom_btn}>
                        <button type="submit" disabled={isSubmitting}>
                          Add Gallery
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className={styles.gallery_container_bottom_listing}>
                <h1>Gallery Listing</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Gallery Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {galleryList.map((gallery, index) => (
                      <tr key={gallery._id || index}>
                        <td>
                          <img
                            src={`${BASE_URL}/${gallery.image}`}
                            alt="Gallery"
                            style={{ width: "100px", height: "auto" }}
                          />
                        </td>
                        <td>
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            onClick={() => handleDelete(gallery._id)}
                            style={{ cursor: "pointer" }}
                            crossorigin="anonymous"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Gallery;
